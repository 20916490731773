import Heading2 from '../../elements/Heading/Heading2';
import Paragraph from '../../elements/Paragraph';
import IllustrationWellDone from '../../svgs/illustrations/well-done.svg';

interface SuccessActionProps {
    headerTitle: string;
    text: string;
    extraHtml?: React.ReactNode;
}

export default function SuccessAction({
    headerTitle,
    text,
    extraHtml = null,
}: SuccessActionProps): JSX.Element {
    return (
        <div className="flex flex-col">
            <IllustrationWellDone className="mx-auto" />
            <Heading2>{headerTitle}</Heading2>
            <Paragraph>{text}</Paragraph>
            {extraHtml}
        </div>
    );
}
