import MagicIcon from 'bootstrap-icons/icons/magic.svg';
import { unstable_getServerSession } from 'next-auth';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '../components/elements/Button';
import Label from '../components/elements/Forms/Label';
import TextInput from '../components/elements/Forms/TextInput';
import Heading2 from '../components/elements/Heading/Heading2';
import Link from '../components/elements/Link';
import LinkButton from '../components/elements/LinkButton';
import Paragraph from '../components/elements/Paragraph';
import BasicCardLayout from '../components/modules/Layout/BasicLayout/BasicCardLayout';
import Seo from '../components/modules/Seo';
import SuccessAction from '../components/modules/SuccessAction';
import routes from '../config/routes';
import { googleSignIn, sendMagicLink, usernameLoginSignIn } from '../features/auth';
import { ErrorMessages } from '../lib/errorMessages';
import type { FormState } from '../lib/helper/form-helper';
import type { PageContext, PageRedirect } from '../lib/interfaces/page';
import { goTo, goToDashboard } from '../lib/page-helper';
import { authOptions } from './api/auth/[...nextauth].page';

type FormValues = {
    email: string;
    password: string;
};

interface SubmitArgs {
    data: FormValues;
    router: NextRouter;
    setStatus: Dispatch<SetStateAction<FormState>>;
    setErrorMessage: Dispatch<SetStateAction<string>>;
}

async function onSubmit({
    data: { email, password },
    router,
    setStatus,
    setErrorMessage,
}: SubmitArgs): Promise<void> {
    setStatus('loading');
    try {
        const response = await usernameLoginSignIn(email, password);
        if (response?.ok) {
            setStatus('success');
            await router.push(routes.dashboard.getUrl());
        } else {
            setStatus('error');
            setErrorMessage('Email ou mot de passe incorrect');
        }
    } catch (error) {
        console.error(error);
        setStatus('error');
        setErrorMessage(ErrorMessages.INTERNAL_ERROR);
    }
}

export interface LoginProps {
    fromPartner?: boolean;
}

export default function Login({ fromPartner = false }: LoginProps): JSX.Element {
    const router = useRouter();
    const [status, setStatus] = useState<FormState>('initial');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const { register, handleSubmit } = useForm<FormValues>({
        defaultValues: { email: '', password: '' },
    });

    if (!fromPartner) {
        return (
            <BasicCardLayout>
                <Seo
                    title="Se connecter"
                    description="Connectez-vous à Partager pour retrouver vos publications préférées et devenir auteur."
                    url={routes.login.getUrl()}
                    isCanonical
                />
                <Heading2 className="!mt-0" level={1}>
                    Se connecter
                </Heading2>
                <form
                    onSubmit={handleSubmit((data) =>
                        onSubmit({
                            data,
                            router,
                            setStatus,
                            setErrorMessage,
                        }),
                    )}>
                    <div className="mb-6">
                        <Label htmlFor="login-email">Adresse e-mail</Label>
                        <TextInput<FormValues>
                            id="login-email"
                            type="email"
                            name="email"
                            register={register}
                            className="w-full"
                        />
                    </div>
                    <div className="mb-6">
                        <Label htmlFor="login-password">Mot de passe</Label>
                        <TextInput<FormValues>
                            id="login-password"
                            type="password"
                            name="password"
                            register={register}
                            className="w-full"
                        />
                        <Link href={routes.lostPassword.getUrl()} className="text-xs mt-1">
                            {routes.lostPassword.name}
                        </Link>
                    </div>
                    <Button loading={status === 'loading'} type="submit" className="!w-full mb-6">
                        Connexion
                    </Button>
                    {status === 'error' && (
                        <Paragraph variant="error" className="text-center">
                            {errorMessage}
                        </Paragraph>
                    )}
                </form>
                <Paragraph className="text-center mb-6">Ou</Paragraph>
                <Button className="!w-full mb-3" color="alternative" onClick={() => googleSignIn()}>
                    {/* TODO add these svgs to a file and import as components */}
                    <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-block  mr-5">
                        <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                            <path
                                fill="#4285F4"
                                d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                            />
                            <path
                                fill="#34A853"
                                d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                            />
                            <path
                                fill="#FBBC05"
                                d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                            />
                            <path
                                fill="#EA4335"
                                d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                            />
                        </g>
                    </svg>
                    Se connecter avec Google
                </Button>
                <LinkButton
                    href={routes.magicLink.getUrl()}
                    color="alternative"
                    className="!w-full mb-6 flex items-center gap-5 justify-center text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 font-bold rounded-lg focus:z-10 focus:ring-2 px-2 lg:px-5 py-2 lg:py-2.5 text-sm">
                    <MagicIcon />
                    Recevoir un lien de connexion
                </LinkButton>
                <Paragraph className="text-center">
                    Vous n’avez pas de compte ?{' '}
                    <Link href={routes.register.getUrl()}>Inscrivez-vous</Link>
                </Paragraph>
            </BasicCardLayout>
        );
    }

    return (
        <BasicCardLayout>
            <Seo
                title="Se connecter"
                description="Connectez-vous à Partager pour retrouver vos publications préférées et devenir auteur."
                url={routes.login.getUrl()}
                isCanonical
            />
            <SuccessAction
                headerTitle="L'e-mail contenant un lien de connexion a été envoyé !"
                text="Vous avez reçu un email pour confirmer votre inscription et un lien de connexion."
                extraHtml={
                    <div className="text-sm">
                        <br />
                        <p>Cliquez sur le lien de connexion pour accéder à votre compte</p>
                        <br />
                        <p>
                            <i>N&apos;hésitez pas à vérifier vos Spams.</i>
                        </p>
                    </div>
                }
            />
        </BasicCardLayout>
    );
}

export async function getServerSideProps(
    context: PageContext,
): Promise<{ props: LoginProps } | PageRedirect> {
    try {
        const session = await unstable_getServerSession(context.req, context.res, authOptions);

        if (typeof context.query.token === 'string') {
            const partnerToken = Buffer.from(context.query.token, 'base64').toString('utf-8');
            const email = partnerToken.split('-')[1];
            const publicationId = partnerToken.split('-')[0];

            if (!session) {
                if (yup.string().email().validateSync(email)) {
                    await sendMagicLink(email, routes.adminPublicationGet.getUrl(publicationId));
                }

                return {
                    props: {
                        fromPartner: true,
                    },
                };
            }

            return goTo(routes.adminPublicationGet.getUrl(publicationId));
        }

        if (session) {
            return goToDashboard();
        }
    } catch (error) {
        console.error(error);
    }

    return {
        props: {},
    };
}
